import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import styled from "styled-components"
import { useMediaQuery } from "react-responsive"
import { navigate } from "gatsby"
import makeList from "../utils/makeList"

import Layout from "../components/Layout"
import { Grid, Col } from "../components/styles/Grid"
import { Section, ParagraphWrapper } from "../components/styles/Container"
import GlobalStyles from "../components/styles/GlobalStyles"

import "../components/styles/klaus-gridski.css"

import Button from "../components/styles/Button"
import Arrow from "../img/icons/arrow.svg"

const ArrowIcon = styled.img`
  height: 20px;
`

const HeadlineWrapper = styled.div`
  margin-top: 10rem;
  animation: transform 0.8s ease-in-out;
  @keyframes transform {
    0% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }
    50% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
`

const MobHeadlineWrapper = styled.div`
  animation: transform 0.8s ease-in-out;
  @keyframes transform {
    0% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }
    50% {
      transform: translate3d(0, 20px, 0);
      opacity: 0;
    }

    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
`

// PRICE +  CURRENCY
const Price = ({ price, rate, currency }) => {
  return ` ${(price * rate).toFixed(2)}${currency}`
}

// CUSTOMIZE SECTION
const Customize = ({ basePrice = 33 }) => {
  const [usd, setUSD] = useState(1)
  const [pound, setPound] = useState(1)
  const [exchangeRate, setExchangeRate] = useState(1)
  const [currency, setCurrency] = useState("€")
  const [currencyValue, setCurrencyValue] = useState("EUR")
  const [isSending, setIsSending] = useState(false)

  useEffect(() => {
    const getExchangeRates = async () => {
      try {
        const res = await fetch(
          "https://api.exchangeratesapi.io/latest?base=EUR&symbols=USD,GBP"
        )
        const { rates } = await res.json()
        setUSD(rates.USD)
        setPound(rates.GBP)
      } catch (err) {
        console.error(err.message)
      }
    }
    getExchangeRates()
  }, [])

  /* BUTTON */

  const changeCurrency = e => {
    const value = e.target.value
    setCurrencyValue(value)
    switch (value) {
      case "EUR":
        setExchangeRate(1)
        setCurrency("€")
        break
      case "USD":
        setExchangeRate(usd)
        setCurrency("$")
        break
      case "GBP":
        setExchangeRate(pound)
        setCurrency("£")
        break
      default:
        setExchangeRate(1)
        break
    }
  }

  const formik = useFormik({
    initialValues: {
      playtime: "4",
      express: false,
      revision: false,
      stemcount: "1",
      message: "",
      email: "",
      price: "",
    },
    onSubmit: values => {
      values.price = `${(total * exchangeRate).toFixed(2)}${currency}`
      values.playtime = `${values.playtime}min`

      let formData = makeList(values)

      setIsSending(true)
      fetch(process.env.GATSBY_MAILER, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${process.env.GATSBY_BEARER}`,
        },
        body: JSON.stringify({
          recipient: process.env.GATSBY_MAIL_RECIPIENT,
          subject: "Customize Mastering Form",
          htmltext: formData,
        }),
      })
        .then(() => {
          setIsSending(false)
          navigate("/thanks")
        })
        .catch(error => {
          setIsSending(false)
          alert(error)
        })
    },
  })

  const [total, setTotal] = useState(basePrice)
  useEffect(() => {
    // Reset price, since we'll recalculate it now
    let price = basePrice

    // Get the price for playtime
    switch (formik.values.playtime) {
      case "4":
        price += 0 * exchangeRate
        break
      case "5":
        price += 6 * exchangeRate
        break
      case "6":
        price += 9 * exchangeRate
        break
      case "8":
        price += 15 * exchangeRate
        break
      default:
        price += 0 * exchangeRate
        break
    }

    // Get the price for stem count
    switch (formik.values.stemcount) {
      case "1":
        price += 0
        break
      case "2":
        price += 9 * exchangeRate
        break
      case "3":
        price += 15 * exchangeRate
        break
      case "4":
        price += 19 * exchangeRate
        break
      case "5":
        price += 25 * exchangeRate
        break
      default:
        price += 0 * exchangeRate
        break
    }
    // Get the price for options
    const { express, revision } = formik.values

    // Check express delivery as last option, since the price must be clear before
    if (express) {
      price += (price / 2) * exchangeRate
    }
    // Revisions are excluded
    if (revision) {
      price += 15 * exchangeRate
    }

    setTotal(price)
  }, [formik.values, basePrice, exchangeRate])

  const isDesktop = useMediaQuery({
    query: "(min-device-width: 75em)",
  })
  const isTablet = useMediaQuery({
    query: "(min-device-width: 48em) and (max-device-width: 75em)",
  })
  const isMobile = useMediaQuery({
    query: "(max-device-width: 48em)",
  })

  // RIGHT SUMMARY SECTION

  const Summary = ({ fields, price }) => (
    <div>
      <Grid gap="5">
        <Col>
          {" "}
          <h3>your selection</h3>
        </Col>
        <Col>
          {/* <ParagraphWrapper>
            One Song <br />
            Mastering <br /> HQ Audio File
          </ParagraphWrapper> */}
          <br />
          <div className="labels">
            {" "}
            <strong>CHOSEN STEM COUNT</strong>
            <p>{fields.stemcount} stems</p>
          </div>
          <div className="labels">
            <strong>CHOSEN PLAYTIME</strong>
            <p>{fields.playtime} min</p>
          </div>
        </Col>
        <Col>
          <div className="added-extras">
            <strong>
              ADDED EXTRAS <br /> from{" "}
              <Price
                price={basePrice}
                rate={exchangeRate}
                currency={currency}
              />{" "}
              <br />
            </strong>
            <ul>{fields.express ? <li>3 days express delivery</li> : null}</ul>
            <ul>
              {fields.revision ? (
                <li>Unlimited revisions for 4 weeks </li>
              ) : null}
            </ul>
          </div>
        </Col>
      </Grid>
      <select onChange={changeCurrency} value={currencyValue}>
        <option value="EUR">EUR</option>
        <option value="USD">USD</option>
        <option value="GBP">GBP</option>
      </select>
      <br />
      <strong>TOTAL</strong>
      <strong className="price">
        <Price price={price} rate={exchangeRate} currency={currency} />
      </strong>
      <br />
    </div>
  )

  return (
    <Layout>
      <GlobalStyles />
      {isDesktop && (
        <HeadlineWrapper>
          <h2>Customize</h2>
        </HeadlineWrapper>
      )}
      {(isMobile || isTablet) && (
        <MobHeadlineWrapper>
          <h2>Customize</h2>
        </MobHeadlineWrapper>
      )}
      <Section>
        <form onSubmit={formik.handleSubmit} name="customize-mastering">
          <Grid gap="7" bg="2">
            <Col>
              <h3>choose details</h3>
              <ParagraphWrapper>
                Qualix will master your song making it jump out of the speakers,
                but still keeping its balance. You will receive an impactful and
                transparent song with competitive loudness.
              </ParagraphWrapper>
              <ParagraphWrapper>
                - 5 days delivery <br />
                - 2 days express delivery <br /> - 2 revisions included
              </ParagraphWrapper>
              <div>
                <input
                  id="check3"
                  className="tab flex vertical"
                  type="checkbox"
                />
                <div className="flex between labels">
                  <label htmlFor="check3" className="tab-label">
                    STEM COUNT
                  </label>
                  <label htmlFor="check3">
                    <ArrowIcon src={Arrow}></ArrowIcon>
                  </label>
                </div>
                <div className="tab-content">
                  <label>
                    <input
                      name="stemcount"
                      type="radio"
                      onChange={formik.handleChange}
                      value="1"
                      defaultChecked
                    />
                    traditional stereo mastering (default)
                  </label>

                  <label>
                    <input
                      name="stemcount"
                      type="radio"
                      onChange={formik.handleChange}
                      value="2"
                    />
                    stem mastering with 2 stems ( +
                    <Price price={9} rate={exchangeRate} currency={currency} />)
                  </label>

                  <label>
                    <input
                      name="stemcount"
                      type="radio"
                      onChange={formik.handleChange}
                      value="3"
                    />
                    stem mastering with 3 stems ( +
                    <Price price={15} rate={exchangeRate} currency={currency} />
                    )
                  </label>

                  <label>
                    <input
                      name="stemcount"
                      type="radio"
                      onChange={formik.handleChange}
                      value="4"
                    />
                    stem mastering with 4 stems ( +
                    <Price
                      price={19}
                      rate={exchangeRate}
                      currency={currency}
                    />{" "}
                    )
                  </label>

                  <label>
                    <input
                      name="stemcount"
                      type="radio"
                      onChange={formik.handleChange}
                      value="5"
                    />
                    stem mastering with 5 stems ( +
                    <Price price={25} rate={exchangeRate} currency={currency} />
                    )
                  </label>
                </div>
              </div>
              <div>
                <input
                  id="check2"
                  className="tab flex vertical"
                  type="checkbox"
                />
                <div className="flex between labels">
                  <label htmlFor="check2" className="tab-label">
                    PLAYTIME
                  </label>
                  <label htmlFor="check2">
                    <ArrowIcon src={Arrow}></ArrowIcon>
                  </label>
                </div>

                <div className="tab-content">
                  <label>
                    <input
                      name="playtime"
                      type="radio"
                      onChange={formik.handleChange}
                      value="4"
                      defaultChecked
                    />
                    4min (default)
                  </label>

                  <label>
                    <input
                      name="playtime"
                      type="radio"
                      onChange={formik.handleChange}
                      value="5"
                    />
                    5min ( +
                    <Price price={6} rate={exchangeRate} currency={currency} />)
                  </label>

                  <label>
                    <input
                      name="playtime"
                      type="radio"
                      onChange={formik.handleChange}
                      value="6"
                    />
                    6min ( +
                    <Price price={9} rate={exchangeRate} currency={currency} />)
                  </label>

                  <label>
                    <input
                      name="playtime"
                      type="radio"
                      onChange={formik.handleChange}
                      value="8"
                    />
                    8min ( +
                    <Price price={15} rate={exchangeRate} currency={currency} />
                    )
                  </label>
                </div>
              </div>
              <div>
                <input
                  id="check1"
                  className="tab flex vertical"
                  type="checkbox"
                />
                <div className="flex between labels">
                  <label htmlFor="check1" className="tab-label">
                    OTHER OPTIONS
                  </label>
                  <label htmlFor="check1">
                    <ArrowIcon src={Arrow}></ArrowIcon>
                  </label>
                </div>

                <div className="tab-content">
                  <label>
                    <input
                      type="checkbox"
                      name="express"
                      onChange={formik.handleChange}
                    />
                    three day delivery (+50%)
                  </label>

                  <label>
                    <input
                      type="checkbox"
                      name="revision"
                      onChange={formik.handleChange}
                    />
                    unlimited revisions for 4 weeks ( +
                    <Price price={15} rate={exchangeRate} currency={currency} />
                    )
                  </label>
                </div>
              </div>
              <div>
                <span className="labels" style={{ display: "block" }}>
                  DROP ME A LINE
                </span>
                <textarea
                  name="message"
                  onChange={formik.handleChange}
                  rows="8"
                  placeholder="Link to your files, additional information etc."
                ></textarea>
                <label
                  htmlFor="email"
                  className="labels"
                  style={{ display: "block" }}
                >
                  YOUR EMAIL
                </label>
                <input
                  type="email"
                  name="email"
                  onChange={formik.handleChange}
                  placeholder="your@email.com"
                  required
                />
              </div>
            </Col>
            <Col>
              <Summary fields={formik.values} price={total} />
              <Button type="submit" disabled={isSending}>
                {isSending ? "sending..." : "send request"}
              </Button>
            </Col>
          </Grid>
        </form>
      </Section>
      <Section></Section>
    </Layout>
  )
}

export default Customize
